@tailwind base;
@tailwind components;
@tailwind utilities;


/* @import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap'); */

/* .react-datepicker-wrapper {
    width: inherit!important;
} */

/* html {
    font-family: 'Poppins', sans-serif;
} */

ion-modal#message-modal {
    --width: fit-content;
    --min-width: 360px;
    --max-width: 360px;
    --height: fit-content;
    --border-radius: 6px;
    --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
}